import React from 'react'
import Layout from '../components/layout'
import Seo from '../components/seo' 

const TermsPage = ({ data }) => (  
  <Layout> 
    <Seo
      title={"findbestwebhosting.com ::TERMS OF SERVICE"}
      description={
        'findbestwebhosting.com ::TERMS OF SERVICE.'
      }
    />
<React.Fragment>
<h2 class="section"><b>
    findbestwebhosting.com ::TERMS OF SERVICE
   </b></h2>
 
    <p>
        <b>
            findbestwebhosting.com ::TERMS OF SERVICE</b>
                </p>
 
    <p>
        Before using
            findbestwebhosting.com, please read the following terms of services carefully .
            By using this web site, you&nbsp;
            indicate
            that you accept these terms of services. If you disagree with the terms
            of service, you may not use the services provided by findbestwebhosting.com.</p>
 
 
    <p>
        <b>
            ACCEPTANCE OF TERMS</b></p>
 
    <p>
        
            findbestwebhosting.com's Services are offered subject to the following terms and
            conditions ("Terms"), which may be amended by findbestwebhosting.com from time to
            time without notice to you. By using the Services, you agree to the Terms. If you
            do not agree, you may not use the Services. It is your responsibility to review
            these Terms from time to time so you will be apprised of any change. In addition,
            use of particular Services may be subject to additional terms and conditions, which
            will be available in connection with such Services. Unless otherwise agreed to in
            writing between you and findbestwebhosting.com, the Terms, as amended, shall govern
            your use of findbestwebhosting.com's World Wide Web site and all Services. Please
            note that at any time, if it is determined by findbestwebhosting.com that you have
            abused or violated the letter or intent of any of these terms, conditions and guidelines,
            findbestwebhosting.com reserves the right to terminate your account immediately
            without notice.</p>
 
    <p>
       
            REGISTRATION; PASSWORDS AND SECURITY</p>
  
    <p>
        
            In order to use certain Services, findbestwebhosting.com requires that you register.
            In registering, you agree to: (a) provide true, accurate, current and complete information
            about yourself as prompted by the applicable registration form(s) and (b) maintain
            and promptly update the information. If you provide any information that is untrue,
            inaccurate, not current or incomplete, or findbestwebhosting.com has reasonable
            grounds to suspect that such information is untrue, inaccurate, not current or incomplete,
            findbestwebhosting.com has the right to refuse any and all current or future use
            of the Services (or any portion thereof) by you. By completing the registration
            you also agree to be bound by any terms provided in the registration process, these
            Terms and any terms applicable to the Services for which you register. In certain
            cases you will receive or designate a user name and password. You are responsible
            for maintaining the confidentiality of the user name and password, and are responsible
            for all activities that occur under your user name and password, whether or not
            authorized by you. findbestwebhosting.com will not be liable for any loss or damage
            arising from your failure to comply with this Section.</p>
 
    <p>
        
            USER INFORMATION; findbestwebhosting.com
                PRIVACY POLICY; FEEDBACK</p>
 
    <p>
        
            findbestwebhosting.com may use information concerning users for internal business.
            Information that you provide to findbestwebhosting.com via the findbestwebhosting.com
            Site is subject to our Privacy Policy that is incorporated by this reference herein. 
                    If you provide us with any feedback,
            suggestions, criticisms or compliments ("Feedback"), you agree that we may use such
            Feedback for any purpose whatsoever, including without limitation, distribution,
            publication, display and use in marketing materials and advertisements.</p>
  
    <p>
        
            Host Reviews:</p>
    <p>
        
            Host Reviews are submitted by users and reflect their own opinion. findbestwebhosting.com
            will not be liable for any loss or damage arising from these opinions. Also, editors
            of findbestwebhosting.com deserve the right to determine if a host review can be
            published or not.
            
        
    </p>
 
    <p>
        
            LIMITATIONS ON USE OF THE SERVICES</p>

    <p>
        
            findbestwebhosting.com may establish rules for and limitations on use of the Services.
            findbestwebhosting.com reserves the right to change these rules and limitations
            at any time, in its sole discretion, with or without notice. findbestwebhosting.com
            may modify or discontinue, temporarily or permanently, the Services (or any part
            thereof) and may remove content from the Services, with or without notice to you.
            findbestwebhosting.com will not be liable to you or to any third party for any modification
            or discontinuance of the Services or removal of content from the Services. You may
            not reproduce, duplicate, copy, sell, resell or exploit for any commercial purposes,
            any portion of the Services, use of the Services, or access to the Services. You
            agree to comply with all applicable treaties, laws, rules and regulations. Information
            provided by findbestwebhosting.com is not warranted to be accurate or complete,
            and will not constitute a warranty or representation by findbestwebhosting.com.</p>

    <p>
        
            As a user of the Services, you agree to use the Services only for lawful purposes.
            Use of the Services for or in conjunction with the transmission, distribution, retrieval,
            or storage of any information, data, or other material in violation of any applicable
            law or regulation is prohibited. You also agree not to use the Services:</p>

    <p>
        
            &nbsp; &nbsp; · In any manner prohibited
            by law or regulation;
                
            
    </p>
    <p>
        
            &nbsp; &nbsp; · To impersonate findbestwebhosting.com
            personnel or any other person;
                
            
    </p>
    <p>
        
            &nbsp; &nbsp; · To use another user's, or
            to allow another person to use your, login or password or account information;
                
            
    </p>
    <p>
        
            &nbsp;&nbsp; · To violate the security or
            interfere with the operations of findbestwebhosting.com, whether intentionally,
            negligently or otherwise;
                
            
    </p>
    <p>
        
            &nbsp; &nbsp; · To access any data, server
            or account which you are not authorized to access;
                
            
    </p>
    <p>
        
            &nbsp; &nbsp; · To test the vulnerability
            or breach the security or authentication measures of any system or network; or
                
            
    </p>
    <p>
        
            &nbsp; &nbsp; · To interfere with the Services
            provided to anyone else.
                
            
    </p>

    <p>
        <b >
            TERMINATION</b></p>

    <p>
        
            findbestwebhosting.com, in its sole discretion, may terminate your use of the Services
            for any reason without prior notice. You acknowledge and agree that findbestwebhosting.com
            may immediately deactivate or delete your user account and all related information
            and files in your user account and/or bar any further access to the Services. findbestwebhosting.com
            will not be liable to you or any third party for any termination of your access
            to the Services.</p>

    <p>
        
            findbestwebhosting.com PARTNERS</p>
    <p>
        
            Your dealings with other entities promoted on or through the Services, including
            payment and delivery of related goods or services, and any other terms, conditions,
            warranties or representations associated with such dealings, are solely between
            you and such other entity. You agree that findbestwebhosting.com shall not be responsible
            or liable for any loss or damage of any sort incurred as the result of any such
            dealings or as the result of the promotion of such other entities on the Services.</p>

    <p>
        
            LINKS</p>
    <p>
        
            The Services may provide links to other World Wide Web sites or resources. findbestwebhosting.com
            has no control over or responsibility for such sites and resources. You agree that
            findbestwebhosting.com is not responsible for the availability of such external
            sites or resources, and does not endorse and is not responsible or liable for any
            content, advertising, products, or other materials on or available from such sites
            or resources. findbestwebhosting.com provides the links to you only as a convenience
            and inclusion of any link does not imply endorsement of the site.</p>

    <p>
        
            findbestwebhosting.com'S PROPRIETARY RIGHTS AND LIMITED LICENSES</p>

    <p>
        
            You acknowledge and agree that the Services and any content, organization, gathering,
            compilation, magnetic translation, digital conversion and other matters related
            to the Site and software used in connection with the Services ("Software") contain
            proprietary information that is protected by applicable intellectual property and
            other laws and, as between you and findbestwebhosting.com, findbestwebhosting.com
            shall own all right, title and interest in and to all proprietary information embodied
            in the Services and the software subject only to the express licenses granted herein.
            Except as expressly authorized by findbestwebhosting.com, you may not modify, rent,
            lease, loan, sell, distribute, transmit, publish or create derivative works based
            on the Services or Software, in whole or in part.</p>

    <p>
        
            Subject to the Terms, findbestwebhosting.com grants you a personal, non-transferable
            and non-exclusive, limited right and license to access and use the Software and
            the Services as provided to you by findbestwebhosting.com on a single computer;
            provided that you do not (and do not allow any third party to) copy, modify, create
            a derivative work of, reverse engineer, reverse assemble or otherwise attempt to
            discover any source code, sell, assign, distribute, sublicense, grant a security
            interest in or otherwise transfer any right in the Software. You agree not to modify
            the Software in any manner or form, or to use modified versions of the Software,
            including (without limitation) for the purpose of obtaining unauthorized access
            to the Services.</p>

    <p>
        
            VIOLATION OF TERMS OF SERVICES</p>
    <p>
        
            In the event of any actual or potential violation of the letter or intent of the
            terms or conditions of these Terms, findbestwebhosting.com reserves the right to
            suspend or terminate, either temporarily or permanently, any or all services provided
            by findbestwebhosting.com, to block any prohibited activity, or to take any other
            actions as deemed appropriate by findbestwebhosting.com in its sole discretion.
            Users who violate these Terms may additionally incur criminal and/or civil liability.
            findbestwebhosting.com may refer violators to civil or criminal authorities for
            prosecution, and will cooperate fully with applicable government authorities in
            connection with its investigations of any suspected civil or criminal violations.</p>

    <p>
        
            INDEMNITY</p>
    <p>
        
            You agree to indemnify, defend and hold harmless findbestwebhosting.com, its affiliates,
            officers, directors, employees, consultants and agents from any and all claims,
            liability, damages and/or costs (including without limitation attorneys' fees and
            legal costs) arising from your use of the Services or Software, or from your violation
            of the Terms. Payment by you under this provision will not prevent findbestwebhosting.com
            from obtaining other legal or equitable remedies against you.</p>

    <p>
        
            DISCLAIMER OF WARRANTIES</p>

    <p>
        
            You expressly understand and agree that:</p>

    <p>
        
            Your use of the services and the software is at your sole risk. The services and
            the software are provided on an "as is" and "as available" basis. findbestwebhosting.com
            expressly disclaims all warranties of any kind, whether express or implied, including,
            but not limited to the implied warranties of merchantability, fitness for a particular
            purpose and non-infringement.</p>

    <p>
        
            findbestwebhosting.com makes no warranty that (i) the services or the software will
            meet your expectations, (ii) the services or the software will be uninterrupted,
            timely, secure, or error-free or (iii) any errors in the services or software will
            be corrected.</p>

    <p>
        
            Any material downloaded or otherwise obtained through the use of the services is
            done at your own discretion and risk and that you will be solely responsible for
            any damage to your computer system or loss of data that results from the download
            of any such material.</p>

    <p>
        
            LIMITATION OF LIABILITY</p>
    <p>
        
            You agree that findbestwebhosting.com shall not be liable for any direct, indirect,
            incidental, special, consequential or exemplary damages, including but not limited
            to, damages for loss of profits, goodwill, use, data or other intangible losses
            (even if findbestwebhosting.com has been advised of the possibility of such damages),
            resulting from: (i) the use or the inability to use the services and/or the software;
            (ii) the cost of procurement of substitute goods and services resulting from the
            services and/or the software; (iii) unauthorized access to or alteration of your
            account by anyone other than findbestwebhosting.com; (iv) statements or conduct
            of any third party on the services; or (v) any other matter relating to the services
            or software.</p>


    <p>
        
            INTERNATIONAL USE</p>
    <p>
        
            Recognizing the global nature of the Internet, you agree to comply with all local
            rules regarding online conduct and acceptable Content. Without limiting the generality
            of the foregoing, you agree to comply with all applicable laws regarding the transmission
            of technical data exported from the  United States   or the country in which you reside.</p>

    <p>
        &nbsp;</p>

 
    </React.Fragment>
  </Layout>
)

export default TermsPage
